<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="名片端" name="user">
        <userTerminal :accountMap="accountMap" @getAppListInfo="getAppListInfo" @resetAuthorization="resetAuthorization"
          v-if="activeName == 'user'" />
      </el-tab-pane>
      <el-tab-pane label="crm端" name="crm" v-if="accountMap[2]">
        <crm :accountMap="accountMap" @getAppListInfo="getAppListInfo" @resetAuthorization="resetAuthorization"
          v-if="activeName == 'crm'" />
      </el-tab-pane>
      <el-tab-pane label="园区端" name="yq">
        <yuanqv :accountMap="accountMap" @getAppListInfo="getAppListInfo" @resetAuthorization="resetAuthorization"
          v-if="activeName == 'yq'" />
      </el-tab-pane>
      <el-tab-pane label="社团端" name="st">
        <shetuan :accountMap="accountMap" @getAppListInfo="getAppListInfo" @resetAuthorization="resetAuthorization"
          v-if="activeName == 'st'" />
      </el-tab-pane>
      <el-tab-pane label="政务端" name="zw">
        <zhengwu :accountMap="accountMap" @getAppListInfo="getAppListInfo" @resetAuthorization="resetAuthorization"
          v-if="activeName == 'zw'" />
      </el-tab-pane>
      <el-tab-pane label="公众号" name="gzh">
        <gzh v-if="activeName == 'gzh'" />
      </el-tab-pane>
      <!-- <el-tab-pane label="基础设置"></el-tab-pane> -->
    </el-tabs>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="是否重置授权信息" width="30%" :center="true" :visible="dialogTableVisible"
      @close="dialogClose">
      <p class="fs8 text-align cA1">
        重置以后需要重新配置小程序信息
      </p>
      <div class="mt40 flex-c-c ">
        <el-button type="primary" plain @click="cancel">
          取消
        </el-button>
        <el-button type="primary" @click="onConfirmShop">
          确认
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import userTerminal from './components/userTerminal';
import crm from './components/crm';

import yuanqv from './components/yuanqv';
import shetuan from './components/shetuan';
import zhengwu from './components/zhengwu';
import gzh from './components/gzh';
import {
  getCompanyAccountList,
  unbindCompanyAccount
} from "@/api/authorization"
export default {
  data() {
    return {
      activeName: "user",
      accountMap: '',
      userInfo: '',
      dialogTableVisible: false,
      accountId: '',
    };
  },
  components: {
    userTerminal,
    crm,
    yuanqv,
    shetuan,
    zhengwu,
    gzh
  },
  created() {
    let wechatType = localStorage.getItem("wechatType")
    if (wechatType) {
      this.activeName = wechatType
    } else {
      localStorage.setItem("wechatType", 'user')
    }
    this.userInfo = JSON.parse(localStorage.getItem('info'))
    this.getAppListInfo()
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
      localStorage.setItem("wechatType", name)
    },
    async getAppListInfo() {
      const result = await getCompanyAccountList({ appid: true })
      //-console.log(result)
      if (result.data) {
        let tmp = {};
        result && result.data.map(val => {
          tmp[val.accountType] = val;
        });
        this.accountMap = tmp;
        //-console.log(this.accountMap)
      }
    },
    dialogClose() {
      this.dialogTableVisible = false
    },
    resetAuthorization(id) {
      this.accountId = id
      this.dialogTableVisible = true
    },
    cancel() {
      this.dialogTableVisible = false
    },
    async onConfirmShop() {
      let data = {
        accountId: this.accountId
      }
      const result = await unbindCompanyAccount(data)
      //-console.log(result)
      if (result.code == 200) {
        this.$message.success('重置成功！')
        this.dialogTableVisible = false
        this.getAppListInfo()
      } else {
        this.$message.error('重置失败，请稍后重试')
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>