<template>
    <div element-loading-text="授权中..." element-loading-spinner="el-icon-loading" v-loading="applyLoading">
        <div class="view pa24">
            <el-button type="primary" @click="showModel = true">
                授权公众号
            </el-button>
            <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
                <template v-slot:table>
                    <el-table-column type="index" align="center" label="序号" />
                    <el-table-column prop="appName" align="center" label="公众号名称" show-overflow-tooltip />
                    <el-table-column prop="appId" align="center" label="APPID" show-overflow-tooltip />
                    <el-table-column prop="createTimeStr" align="center" label="授权时间" show-overflow-tooltip />
                    <el-table-column align="center" label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button class="ml10" type="text" @click="showOptionTableFun(scope.row)">
                                同步设置
                            </el-button>
                            <el-button class="mr10" type="text" @click="updateFun(scope.row)">
                                编辑
                            </el-button>
                            <customPopconfirm confirm-button-text="确定" cancel-button-text="取消" @confirm="delFun(scope.row)"
                                icon="el-icon-info" icon-color="red" title="确定要删除这条公众号？">
                                <el-button slot="reference" style="color: #F56C6C" type="text">删除</el-button>
                            </customPopconfirm>
                        </template>
                    </el-table-column>
                </template>
            </commonTable>
        </div>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :visible.sync="showModel" title="公众号授权"
            width="600px">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" label-width="100px">
                <el-form-item label="公众号名称" prop="appName">
                    <el-input placeholder="请输入公众号名称" v-model="ruleForm.appName"></el-input>
                    <p>
                        公众号的名称（请于微信公众号平台中的公众号名称保持一致，进入【设置】--【基本设置】--【公众号名称】查看）
                    </p>
                </el-form-item>
                <el-form-item label="appId" prop="appId">

                    <el-input type="text" placeholder="请输入公众号AppId" v-model="ruleForm.appId"></el-input>
                    <p>
                        登录公众号后台（
                        <a style="color: #20aee3" target="_blank" href="https://mp.weixin.qq.com">mp.weixin.qq.com</a>
                        ）进入【设置】--【开发设置】--【开发设置AppID,wx开头18位字符】）
                    </p>
                </el-form-item>
                <el-form-item label="AppSecret" prop="appSecret">
                    <el-input placeholder="请输入公众号AppSecret" type="text" v-model="ruleForm.appSecret"></el-input>
                    <p>
                        登录公众号后台（
                        <a style="color: #20aee3" target="_blank" href="https://mp.weixin.qq.com">mp.weixin.qq.com</a>
                        ）进入【设置】--【开发设置】--【AppSecret（公众号密钥)】--生成密钥（32位字符）
                    </p>
                </el-form-item>
                <el-form-item>
                    <el-col :lg="11">
                        <el-button type="primary" @click="authorization">授 权</el-button>
                    </el-col>
                </el-form-item>

            </el-form>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :visible.sync="showSetAppSecret"
            title="编辑公众号" width="600px">
            <el-form :model="appSecretData" :rules="appSecretRules" ref="appSecretRules" label-width="120px"
                class="demo-ruleForm">
                <el-form-item label="AppSecret：" prop="appSecret">
                    <el-input v-model="appSecretData.appSecret" placeholder="请输入AppSecret">
                    </el-input>
                    <p>
                        登录公众号后台（
                        <a style="color: #20aee3" target="_blank" href="https://mp.weixin.qq.com">mp.weixin.qq.com</a>
                        ）进入【设置】--【开发设置】--【AppSecret（公众号密钥)】--生成密钥（32位字符）
                    </p>
                </el-form-item>
                <el-form-item style="text-align: right">
                    <el-button type="primary" size="small" @click="setOfficialAccount()">保 存</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :visible.sync="showOptionTable"
            title="公众号文章同步设置" width="90%">
            <el-button type="primary" @click="addOptionDataFun">
                添加同步配置
            </el-button>
            <commonTable :tableData="optionList" :loading="loadingOptionList" :paginationVisible="false">
                <template v-slot:table>
                    <el-table-column type="index" align="center" label="序号" />
                    <el-table-column prop="appName" align="center" label="公众号名称" show-overflow-tooltip />
                    <el-table-column prop="appId" align="center" label="同步类型" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div>
                                {{ optionTypeData[scope.row.optionType] }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="appId" align="center" label="同步设置类型" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <div v-if="scope.row.optionType === 1">
                                {{ optionCompanyType[scope.row.type] }}
                            </div>
                            <div v-else>
                                {{ scope.row.associationName+" - "+scope.row.typeName }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button class="mr10" type="text" @click="updateOptionDataFun(scope.row)">
                                编辑
                            </el-button>
                            <customPopconfirm confirm-button-text="确定" cancel-button-text="取消"
                                @confirm="delOption(scope.row)" icon="el-icon-info" icon-color="red" title="确定要删除这条文章同步设置？">
                                <el-button slot="reference" style="color: #F56C6C" type="text">删除</el-button>
                            </customPopconfirm>
                        </template>
                    </el-table-column>
                </template>
            </commonTable>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :visible.sync="showSetOption" title="同步设置"
            width="400px">
            <el-form :model="optionFormData" :rules="optionRules" ref="optionRules" label-width="120px"
                class="demo-ruleForm">
                <el-form-item label="公众号" prop="appName">
                    {{ checkedGZH.appName }}
                </el-form-item>
                <el-form-item label="设置类型" prop="optionType">
                    <el-select @change="optionTypeChange" v-model="optionFormData.optionType" placeholder="请选择">
                        <el-option v-for="(title, id) in optionTypeData" :key="id" :label="title" :value="Number(id)">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="文章类型" prop="type" v-if="optionFormData.optionType == 1">
                    <el-select v-model="optionFormData.type" placeholder="请选择">
                        <el-option v-for="(title, id) in optionCompanyType" :key="id" :label="title" :value="Number(id)">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="社团选择" :loading="setLoadingOption" :disabled="associationData.length === 0"
                 prop="associationId" v-if="optionFormData.optionType == 2">
                    <el-select @change="associationChange" v-model="optionFormData.associationId" placeholder="请选择">
                        <el-option v-for="(row, index) in associationData" :key="index" :label="row.associationName"
                            :value="Number(row.associationId)">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="文章类型" prop="type" :disabled="associationData.length === 0"
                    v-if="optionFormData.optionType == 2">
                    <el-select v-model="optionFormData.type" placeholder="请选择">
                        <el-option v-for="(row, index) in optionAssociationType" :key="index" :label="row.title"
                            :value="Number(row.typeId)">

                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="small" @click="setOptionDataFun()" :loading="setLoadingOptionBtn">设
                        置</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
  
<script>
import customPopconfirm from "@/components/common/customPopconfirm";
import { setOfficialAccount, setOfficialAccountOption } from "@/api/authorization";
import { getOfficialAccountList, getOfficialAccountOptionList } from "@/api/wechat";
import { getContentTypeList } from "@/api/association";
import { getDataTimeSec } from "@/utils";
import commonTable from "@/components/common/commonTable";
import { getAssociationManageList } from "@/api/association";
export default {
    components: {
        commonTable,
        customPopconfirm
    },
    data() {
        return {
            accountType: 5,
            wxapplyUrl: require("@/static/wxapply.png"),
            tableData: [],
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            ruleForm: {
                appName: "",
                appId: "",
                appSecret: "",
            },
            showSetAppSecret: false,
            appSecretData: {
                accountId: "",
                appSecret: ""
            },
            appSecretRules: {
                appSecret: [
                    { required: true, message: "请输入公众号AppSecret", trigger: "blur" },
                ],
            },
            showModel: false,
            rules: {
                appName: [
                    { required: true, message: "请输入公众号名称", trigger: "blur" },
                ],
                appId: [
                    { required: true, message: "请输入公众号AppId", trigger: "blur" },
                ],
                appSecret: [
                    { required: true, message: "请输入公众号AppSecret", trigger: "blur" },
                ],
            },
            applyLoading: false,
            //设置列表配置
            showOptionTable: false,//设置列表显示状态
            optionList: [],//设置列表
            loadingOptionList: false,//设置列表加载状态
            optionTypeData: {//同步设置类型数据
                1: "公司文章",
                2: "社团文章"
            },
            optionCompanyType: {//同步公司文章的设置类型
                1: "获客文章",
                // 2: "动态管理",
                // 3: "转载文章",
                4: "新闻资讯"
            },
            optionAssociationType: [],//同步社团文章的设置类型
            //设置内容
            associationData: [],
            checkedGZH: {},//编辑的公众号
            setLoadingOption: false,//设置状态
            setLoadingOptionBtn: false,
            optionFormData: {
                appId: "",
                optionType: 1,
                associationId: "",
                type: "",
            },
            optionRules: {
                associationId: [
                    { required: true, message: "请选择社团", trigger: "change" },
                ],
                type: [
                    { required: true, message: "请选择文章类型", trigger: "change" },
                ]
            },
            showSetOption: false,
            userInfo: this.$store.state.loginRoot.userInfo,
        };
    },
    created() {
        this.getOfficialAccountList();
        this.getAssociationManageList();
    },
    filters: {
        getDataTimeSec(val) {
            return getDataTimeSec(val);
        },
    },
    methods: {
        //设置列表配置
        optionTypeChange(){
            this.optionFormData.type="";
        },
        associationChange(val) {
            this.getContentTypeList(val);
        },
        /**@method 获取社团数据 */
        getAssociationManageList() {
            getAssociationManageList({
                companyId: this.userInfo.companyId,
                noParent: 0
            }).then(res => {
                if (res.data) {
                    this.associationData = res.data;
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },
        /**@method 获取社团动态数据 */
        getContentTypeList(associationId) {
            this.optionAssociationType = [];
            this.setLoadingOption = true;
            getContentTypeList({ associationId: associationId }).then(res => {
                if (res.code === 200) {
                    let tagData = [];
                    for (let row of res.data) {
                        tagData.push({ title: row.typeName, dictValue: row.dictValue, typeId: row.typeId });
                    }
                    this.optionAssociationType = tagData;
                }
            }).catch(err => {
                this.setLoadingOption = false;
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },
        /**@method 新增同步设置 */
        addOptionDataFun() {
            this.showSetOption = true;
            this.optionFormData = {
                appId: this.checkedGZH.appId,
                optionType: 1,
                associationId: "",
                type: "",
            };
        },
        /**@method 编辑同步设置 */
        updateOptionDataFun(row) {
            this.showSetOption = true;
            this.optionFormData = {
                appId: row.appId,
                optionType: row.optionType,
                associationId: row.associationId,
                type: row.type,
            };
            if (row.optionType === 2) {
                this.getContentTypeList(row.associationId);
            }
        },
        /**@method 提交同步设置 */
        setOptionDataFun() {
            this.$refs.optionRules.validate((valid) => {
                if (valid) {
                    let params = {
                        ...this.optionFormData
                    };
                    if (params.optionType == 2) {
                        delete params.associationId
                    }
                    this.setLoadingOptionBtn = true;
                    setOfficialAccountOption(this.optionFormData).then(res => {
                        this.setLoadingOptionBtn = false;
                        if (res.code === 200) {
                            this.$message({
                                message: "设置成功",
                                type: "success"
                            })
                            this.getOfficialAccountOptionList(this.checkedGZH.appId);
                            this.showSetOption = false;
                        } else {
                            this.$message.error(res.message)
                        }
                    }).catch(err => {
                        this.setLoadingOptionBtn = false;
                        if (err.code === 201) {
                            this.$message.error(err.message)
                        }
                    })
                }
            })
        },
        /**@method 删除同步设置 */
        delOption(row) {
            let params = {
                isDel: 1,
                optionId: row.optionId,
            };
            setOfficialAccountOption(params).then(res => {
                if (res.code === 200) {
                    this.$message({
                        type: "success",
                        message: "删除成功",
                    })
                    this.getOfficialAccountOptionList(row.appId);
                } else {
                    this.$message.error(res.message)
                }
            }).catch(err => {
                this.loadingOptionList = false;
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },
        /**@method 显示设置列表 */
        showOptionTableFun(row) {
            this.getOfficialAccountOptionList(row.appId);
            this.checkedGZH = row;
            this.showOptionTable = true;
        },
        /**@method 获取设置列表 */
        getOfficialAccountOptionList(appId) {
            let params = {
                appId: appId
            };
            this.loadingOptionList = true;
            getOfficialAccountOptionList(params).then(res => {
                this.loadingOptionList = false;
                if (res.code === 200) {
                    this.optionList = res.data;
                } else {
                    this.$message.error(res.message)
                }
            }).catch(err => {
                this.loadingOptionList = false;
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },
        //设置列表配置
        /**@method 换行 */
        handleSizeChange(val) {
            this.pageSize = val
            this.getOfficialAccountList();
        },
        /**@method 分页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getOfficialAccountList();
        },
        /**@method 获取列表 */
        getOfficialAccountList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage
            };
            this.loading = true;
            getOfficialAccountList(params).then(res => {
                this.loading = false;
                if (res.data) {
                    this.tableData = res.data.pageInfo.list;
                    this.total = res.data.pageInfo.total
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(res.message)
                }
            })
        },
        updateFun(row) {
            this.showSetAppSecret = true;
            this.appSecretData.accountId = row.accountId;
        },
        delFun(row) {
            setOfficialAccount({
                accountId: row.accountId,
                isDel: 1,
            }).then(res => {
                if (res.code === 200) {
                    this.$message({
                        message: "删除成功",
                        type: "success"
                    })
                    this.currentPage = 1;
                    this.getOfficialAccountList();
                } else {

                    this.$message.error(res.message)
                }
            }).catch(err => {
                if (err.code === 201) {
                    this.$message.error(err.message)
                }
            })
        },
        setOfficialAccount() {
            this.$refs.appSecretRules.validate((valid) => {
                if (valid) {
                    setOfficialAccount(this.appSecretData).then(res => {
                        if (res.code === 200) {
                            this.$message({
                                message: "设置成功",
                                type: "success"
                            })
                            this.currentPage = 1;
                            this.getOfficialAccountList();
                            this.showSetAppSecret = false;
                        } else {
                            this.$message.error(res.message)
                        }
                    }).catch(err => {
                        if (err.code === 201) {
                            this.$message.error(err.message)
                        }
                    })
                }
            })
        },
        authorization() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    setOfficialAccount(this.ruleForm).then(res => {
                        if (res.code === 200) {
                            this.$message({
                                message: "设置成功",
                                type: "success"
                            })
                            this.currentPage = 1;
                            this.getOfficialAccountList();
                            this.showModel = false;
                        } else {
                            this.$message.error(res.message)
                        }
                    }).catch(err => {
                        if (err.code === 201) {
                            this.$message.error(err.message)
                        }
                    })

                }
            })
        },
    },
};
</script>
  
<style lang="scss" scoped>
.register {
    display: flex;
    background: #cdfffe;
    align-items: center;
    padding: 10px 20px;
    border-radius: 10px;
    color: #51cdcb;
    font-size: 14px;

    .el-button {
        margin-left: 20px;
        height: 33px;
    }
}

.authorize {
    display: flex;
    background: #cdfffe;
    width: 300px;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    border-radius: 10px;

    .wxapplyUrl {
        background: #fff;
        height: 50px;
        width: 50px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        .wxapplyImg {
            height: 30px;
            width: 30px;
        }
    }

    .el-button {
        height: 33px;
    }
}

table th {
    background: #f7fafa;
}

tbody:hover {
    background: #f7fafa;
}

table tr {
    height: 50px;
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.fixed-table {
    table-layout: fixed;
}

.textc {
    text-align: center;
}

.bgf6f9fa {
    background: #f6f9fa;
}

.table-bordered {
    border: 1px solid #dee2e6;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}

body * {
    font-family: "Microsoft YaHei UI";
}

table {
    border-collapse: collapse;
}

* {
    outline: none;
}

*,
::after,
::before {
    box-sizing: border-box;
}
</style>
  