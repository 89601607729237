<template>
  <div element-loading-text="授权中..." element-loading-spinner="el-icon-loading" v-loading="applyLoading">
    <div class="register" v-if="accountMap[accountType] && accountMap[accountType].state == 1">
      <div>已授权小程序</div>
      <el-button type="primary" @click="hrefFun('/wxApplyDetails?type=user')">立即查看</el-button>
    </div>
    <div class="register" v-else>
      <div>尚未注册小程序？ 点此快速注册小程序</div>
      <el-button type="primary" @click="hrefFun('/registerWxApply?type=user')">立即注册</el-button>
    </div>
    <div class="view pa24">
      <div v-if="accountMap[accountType] && accountMap[accountType].state == 1">
        <table class="
              align-cen-table
              textc
              table table-hover table-bordered
              bgf6f9fa
              fixed-table
              text-ellipsis
            ">
          <thead>
            <tr>
              <th>小程序名称</th>
              <th>APPID</th>
              <th>原始ID</th>
              <th>授权时间</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody class="bgfff">
            <tr>
              <td>{{ accountMap[accountType].appName }}</td>
              <td>{{ accountMap[accountType].appId }}</td>
              <td>{{ accountMap[accountType].ghId }}</td>
              <td>
                {{
                  accountMap[accountType].createTime
                  | getDataTimeSec("yyyy-MM-dd hh:mm:ss")
                }}
              </td>
              <td>
                <el-button type="primary" v-if="accountMap[accountType].appSecret"
                  @click="showSetAppSecret = true">修改AppSecret</el-button>
                <el-button type="primary" v-else @click="showSetAppSecret = true">设置AppSecret</el-button>
              </td>
            </tr>
          </tbody>
        </table>
        <el-button type="primary" @click="resetAuthorization">重置授权</el-button>
        <el-button type="primary" @click="updateAuthorization">更新授权</el-button>
      </div>

      <el-form v-else :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" class="demo-ruleForm">
        <el-form-item label="是否使用第三方授权">
          <el-col :lg="11">
            <el-radio-group v-model="openingType">
              <el-radio :label="1">否</el-radio>
              <el-radio :label="2">是</el-radio>
            </el-radio-group>
          </el-col>
        </el-form-item>
        <div v-if="openingType === 2">
          <el-form-item>
            <el-col :lg="11">
              <div class="authorize">
                <div class="wxapplyUrl">
                  <img class="wxapplyImg" :src="wxapplyUrl" alt="" />
                </div>
                <div>授权小程序</div>
                <el-button type="primary" @click="getEwmCode()">立即授权</el-button>
              </div>
            </el-col>
          </el-form-item>
        </div>
        <div v-if="openingType === 1">
          <el-form-item label="App名称" prop="appName">
            <el-input placeholder="请输入小程序名称" style="width:300px;" v-model="ruleForm.appName"></el-input>
            <p>
              小程序的名称（
              <a style="color: #20aee3" target="_blank" href="https://mp.weixin.qq.com">mp.weixin.qq.com</a>
              ）请于微信小程序平台中的小程序名称保持一致，进入【设置】--【基本设置】--【小程序名称】查看
            </p>
          </el-form-item>
          <el-form-item label="appId" prop="appId">
            <el-input type="text" placeholder="请输入小程序AppId" style="width:300px;" v-model="ruleForm.appId"></el-input>
            <p>
              登录小程序后台（
              <a style="color: #20aee3" target="_blank" href="https://mp.weixin.qq.com">mp.weixin.qq.com</a>
              ）进入【开发管理】--【开发设置】--【开发设置AppID,wx开头18位字符】）
            </p>
          </el-form-item>
          <el-form-item label="AppSecret" prop="appSecret">
            <el-input type="text" placeholder="请输入小程序AppSecret" style="width:300px;" v-model="ruleForm.appSecret"
              maxlength="32">
            </el-input>
            <p>
              登录小程序后台（
              <a style="color: #20aee3" target="_blank" href="https://mp.weixin.qq.com">mp.weixin.qq.com</a>
              ）进入【开发管理】--【开发设置】--【AppSecret（小程序密钥)】--生成密钥（32位字符）
            </p>
          </el-form-item>
          <el-form-item label="原始ID" prop="ghId">
            <el-input type="text" placeholder="请输入小程序原始ID" style="width:300px;" v-model="ruleForm.ghId"
              maxlength="32"></el-input>
            <p>
              登录小程序后台（
              <a style="color: #20aee3" target="_blank" href="https://mp.weixin.qq.com">mp.weixin.qq.com</a>
              ）进入【设置】--【基本设置】--【原始ID】查看）
            </p>
          </el-form-item>
          <el-form-item>
            <el-col :lg="11">
              <el-button type="primary" @click="authorization">授权</el-button>
            </el-col>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :visible.sync="showSetAppSecret" title="编辑小程序"
      width="600px">
      <el-form :model="appSecretData" :rules="appSecretRules" ref="appSecretRules" label-width="120px"
        class="demo-ruleForm">
        <el-form-item label="AppSecret：" prop="appSecret">
          <el-input v-model="appSecretData.appSecret" placeholder="请输入AppSecret">
          </el-input>
          <p>
            登录小程序后台（
            <a style="color: #20aee3" target="_blank" href="https://mp.weixin.qq.com">mp.weixin.qq.com</a>
            ）进入【设置】--【开发设置】--【AppSecret（小程序密钥)】--生成密钥（32位字符）
          </p>
        </el-form-item>
        <el-form-item style="text-align: right">
          <el-button type="primary" size="small" @click="setAppSecret()">保 存</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { componentloginpage, getAuthCodeLink, commitAndSave, setAppSecret, appletComponentloginpage } from "@/api/authorization";
import { componentCallBack, getAuthorizer } from "@/api/wechat";
import { getDataTimeSec } from "@/utils";
export default {
  props: {
    accountMap: {
      default: "",
    },
  },
  data() {
    return {
      accountType: 1,
      applyLoading: false,
      wxapplyUrl: require("@/static/wxapply.png"),
      openingType: 1, //是否使用第三方授权
      ruleForm: {
        appName: "",
        appId: "",
        appSecret: "",
        ghId: "",
      },
      rules: {
        appName: [
          { required: true, message: "请输入小程序名称", trigger: "blur" },
        ],
        appId: [
          { required: true, message: "请输入小程序AppId", trigger: "blur" },
        ],
        appSecret: [
          { required: true, message: "请输入小程序AppSecret", trigger: "blur" },
        ],
        ghId: [
          { required: true, message: "请输入小程序原始ID", trigger: "blur" },
        ],
      },
      showSetAppSecret: false,
      appSecretData: {
        appId: "",
        appSecret: ""
      },
      appSecretRules: {
        appSecret: [
          { required: true, message: "请输入小程序AppSecret", trigger: "blur" },
        ],
      },
      idMap: {
        1: "userApp",
        2: "crmApp",
        3: "pcData",
        4: "payInfo",
      },
      auth_code: ""
    };
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
  },
  created() {
    let userAppInfo = JSON.parse(localStorage.getItem("userAppInfo"));
    if (userAppInfo) {
      this.ruleForm = userAppInfo;
    }
    let wechatType = localStorage.getItem("wechatType")
    this.auth_code = this.$route.query.auth_code ? this.$route.query.auth_code : "";
    this.$router.push("/appletConfig")
    if (this.auth_code && wechatType == "user" && !this.accountMap[this.accountType]) {
      let empowerType = localStorage.getItem("empowerType")
      if (empowerType && empowerType == 2) {
        this.authorization2();
      } else {
        //oem授权回调
        let userAppId = localStorage.getItem("userAppId");
        if (userAppId) {
          this.applyLoading = true;
          componentCallBack({
            authCode: this.auth_code,
            appId: userAppId,
          }).then((res) => {
            this.applyLoading = false;
            this.auth_code = "";
            localStorage.removeItem("userAppId");
            this.getAppInfo();
          });
        }
      }
    }

  },
  computed: {
    checkAppSecret() {
      return this.ruleForm.AppSecret ? "" : "password";
    },
  },
  methods: {
    setAppSecret() {
      this.appSecretData.appId = this.accountMap[this.accountType].appId;
      this.$refs.appSecretRules.validate(async (valid) => {
        if (valid) {
          let res = await setAppSecret(this.appSecretData)
          if (res.code == 200) {
            this.$message({
              message: "设置成功",
              type: "success"
            })
            this.appSecretData.appSecret = "";
            this.showSetAppSecret = false;
          }
        }
      })
    },
    async getEwmCode() {
      getAuthCodeLink({}).then(res => {
        if (res.code === 200) {
          localStorage.setItem("empowerType", 2)
          location.href = res.data;
        }
      }).catch(err => {
        this.$message.error(err.message)
      })
    },
    //跳转
    hrefFun(url) {
      this.$router.push(url)
    },
    async authorization2() {//立即授权
      this.applyLoading = true;
      let res = await getAuthorizer({
        authorizationCode: this.auth_code,
        accountType: this.accountType,
      })
      this.applyLoading = false;
      if (res.code === 200) {
        this.auth_code = "";
        this.getAppInfo();
        // let authorizer_info=res.data.authorizer_info;
        // let authorization_info=res.data.authorization_info;
        // let data = {
        //   appName: authorizer_info.nick_name,
        //   appId: authorization_info.authorizer_appid,
        //   ghId: authorizer_info.user_name,
        //   accountType: 1, //用户1
        // };
        // commitAndSave(data).then((res) => {//保存授权信息
        //   localStorage.setItem("userAppId", authorization_info.authorizer_appid);
        //   this.getAppInfo();//获取授权信息
        // });
      }
    },
    authorization() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = {
            appName: this.ruleForm.appName,
            appId: this.ruleForm.appId,
            appSecret: this.ruleForm.appSecret,
            ghId: this.ruleForm.ghId,
            accountType: this.accountType, //用户1
          };
          componentloginpage(data).then((res) => {
            localStorage.setItem("userAppId", this.ruleForm.appId);
            localStorage.setItem("empowerType", 1)
            location.href = res.data;
          });
        }
      })
    },
    getAppInfo() {
      this.$emit("getAppListInfo");
    },
    resetAuthorization() {
      let accountId = "";
      for (var i in this.accountMap) {
        if (this.accountMap[i].accountType == this.accountType) {
          accountId = this.accountMap[i].accountId;
        }
      }
      if (!accountId) {
        this.$message.error("还未进行授权");
        return;
      }
      this.$emit("resetAuthorization", accountId);
    },
    updateAuthorization() {
      appletComponentloginpage({
        accountType: this.accountType,
        authorizerAppid: this.accountMap[this.accountType].appId
      }).then(res => {
        location.href = res.data;
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.register {
  display: flex;
  background: #cdfffe;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  color: #51CDCB;
  font-size: 14px;

  .el-button {
    margin-left: 20px;
    height: 33px;
  }
}

.authorize {
  display: flex;
  background: #cdfffe;
  width: 300px;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  border-radius: 10px;

  .wxapplyUrl {
    background: #fff;
    height: 50px;
    width: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    .wxapplyImg {
      height: 30px;
      width: 30px;
    }
  }

  .el-button {
    height: 33px;
  }
}

table th {
  background: #f7fafa;
}

tbody:hover {
  background: #f7fafa;
}

table tr {
  height: 50px;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fixed-table {
  table-layout: fixed;
}

.textc {
  text-align: center;
}

.bgf6f9fa {
  background: #f6f9fa;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

body * {
  font-family: "Microsoft YaHei UI";
}

table {
  border-collapse: collapse;
}

* {
  outline: none;
}

*,
::after,
::before {
  box-sizing: border-box;
}
</style>
